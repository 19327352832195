:root {
  font: 16px / 1 sans-serif;
}

html {
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  min-height: 100%;
  background-color: #b3d4fc;
  /* background-color: #dddddd */
}

a {
  color: #408044;
  text-decoration:  underline;
  font-weight: bold;
}

.logo {
  float: left;
}

nav {
  text-align: center;
  height: auto;
  background-color: white;
}

#nav-mobile li a {
  font-size: 3.7mm;
}


#nav-mobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0px;
  margin: 0px;
  background-color: black;
  width: 100%;
}
 
@keyframes bounce {
  0% {bottom: 2px;}
  25% {bottom: 4px;}
  50% {bottom: 18px;}
  75% {bottom: 12px;}
  100% {bottom: 15px;}
}

#nav-mobile li:hover {
  background-color: #bbbbbb;
  position: relative;
  animation-name: bounce;
  animation-duration: 1.5s;
}

.banner {
  height: 100%;
  padding-top: 30px;
  padding-bottom: 80px;
  background-color: rgb(242, 242, 129);
  font-family: 'Dancing Script', cursive; 
  text-align: center;
  font-size: 28pt;
  margin-top: -20px;
  margin-bottom: 0px;
}

@keyframes fadeout {
  from {
    word-break: break-all;
    margin-left: 100%;
  }

  to {
    word-break: break-all;
    margin-left: 2%
  
  }
}

.content {
  animation: fadeout 750ms;
  max-width: 95%;
  margin-top: 120px;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: sans-serif;
}

.chip {
  font-size: 0.91rem;
  height: 40px;
  padding: 10px;
  background-color: rgb(250, 132, 132);
  margin-bottom: 20px;
}

.collection {
  width: 350px;
}

.table {
  display: flex;
  justify-content: center;
  width: 100%;
}

td {
  border: 1px solid black;
}

.mapped-imgs {
  margin-bottom: 20px;
}

.bold {
  margin-top: 30px;
  font-style: italic;
  font-weight: bold;
}

#contact-table{
  display: flex;
  justify-content: center;
}

#contact-table tr td{
  width: 350px;
  background-color: rgb(250, 250, 164);
  vertical-align: top;
}

.mapped-videos {
  min-width: 400px;
  margin-top: 35px;
  margin-right: 35px;
}

.footer {
  padding-bottom: 50px;
}

.carousel {
 display: flex;
 justify-content: center;
}

.dropdown {
  z-index: 10000;
  display: flex;
  position: fixed;
  top: 36px;
  right: 0px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  background-color: #5050FF;
  margin-right: 0px;
}

.dropdown-menu-item {
  color: white;
  margin-bottom: 5px;
  padding: 10px;
}

@media(min-width:992px){
  .dropdown {
    display: none;
  }
}

#dropdown-button {
  background-color: #5050FF;
  z-index: 10000;
}

@media(min-width:300px) and (max-width:830px){
  #nav-mobile {
    width: 100%;
  }

  .banner {
    padding-bottom: 10px;
  }

  .content {
    margin-top: 10px;
  }

  .chip {
    height: 100px;
    background-color: rgb(250, 132, 132);
  }

  .banner {
    width: 100%;
  }

  .logo {
    width: 100%;
  }
  .mapped-videos {
    min-width: 200px;
  }

}
@media(min-width:400px) and (max-width:992px){
    .content{
      margin-top: 20px;
    }
}

@media(min-width:830px) and (max-width:1178px){
  .banner {
    padding-bottom: 40px;
  }
}

@media(min-width:1179px) and (max-width: 1222px){
  .banner {
    padding-bottom: 40px;
  }
}

#dropdown-button {
  position: fixed;
  top: 0px;
  left: 0px;
}

.footer {
  width: 95%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
}

.return2024 {
  max-width: 600px;
  float: right;
}

.ssblogo {
  max-width: 500px;
}
